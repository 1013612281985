import { Hide } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import React, { FunctionComponent } from 'react'

import ProductAddToCart from 'components/modules/Product/ProductAddToCart'
import ProductQuantityInput from 'components/modules/Product/ProductQuantityInput'

interface ProductListOrderControlsProps {
  productId: string
  variantId: number
  minQuantity: number
  maxQuantity: number
  quantity: number
  setQuantity(quantity: number): void
  unit: string
  erpWarehouse: string
  erpFacility: string
}

/**
 * Used for E2E tests.
 */
const TAG = 'ProductListOrderControls'

const ProductListOrderControls: FunctionComponent<ProductListOrderControlsProps> = ({
  minQuantity = 1,
  maxQuantity,
  quantity,
  setQuantity,
  productId,
  variantId,
  unit,
  erpWarehouse,
  erpFacility,
}) => {
  const { t } = useTranslation('common')

  return (
    <>
      <ProductQuantityInput
        minQuantity={minQuantity}
        maxQuantity={maxQuantity}
        unit={unit}
        debouncedSetQuantity={setQuantity}
        mr={{ base: '0', sm: '4' }}
        mb="0"
        showLabel={false}
      />
      <ProductAddToCart
        minW={{ base: '80px', xl: '142px' }}
        fontSize="sm"
        productId={productId}
        variantId={variantId}
        quantity={quantity}
        minQuantity={minQuantity}
        erpWarehouse={erpWarehouse}
        erpFacility={erpFacility}
      >
        <Hide below="sm">{t('components.cart.purchaseNow')}</Hide>
      </ProductAddToCart>
    </>
  )
}

ProductListOrderControls.displayName = TAG

export default ProductListOrderControls
