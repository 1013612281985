import type { Attribute } from '@commercetools/platform-sdk'

import getAttributeValue from 'components/modules/Product/utils/getAttributeValue'
import { attributeGroupsMap } from 'config/product'

/**
 * @returns filtered attributes by erpProduct and erpItemGroup
 *
 * @param attributes - Attributes from Master Variant
 *
 * Please use function with useMemo
 */
const getProductAttributesByItemGroup = (attributes?: Attribute[]) => {
  if (!attributes) {
    return []
  }
  const erpProductGroup = getAttributeValue('erpProductGroup', attributes)
  const erpItemGroup = getAttributeValue('erpItemGroup', attributes)

  const selectedAttributes =
    attributeGroupsMap.get(`${erpProductGroup}-${erpItemGroup}`) ||
    attributeGroupsMap.get(erpProductGroup) ||
    (erpItemGroup.includes('WT') ? attributeGroupsMap.get(`${erpProductGroup}-WT`) : attributeGroupsMap.get('default'))

  return attributes.filter((attribute) => selectedAttributes?.includes(attribute.name) && attribute.value)
}
export default getProductAttributesByItemGroup
