import { Flex } from '@chakra-ui/react'
import type { Price } from '@commercetools/platform-sdk'
import useTranslation from 'next-translate/useTranslation'
import React, { FunctionComponent } from 'react'

import { DT, DD } from 'components/elements/DescriptionListElements'
import getCustomerItemNumber from 'components/modules/Product/utils/getCustomerItemNumber'

interface ProductItemNumberProps {
  price: Price | undefined
}

/**
 * Used for E2E tests.
 */
const TAG = 'ProductItemNumber'

const ProductItemNumber: FunctionComponent<ProductItemNumberProps> = ({ price }) => {
  const { t } = useTranslation('product')

  const itemAlias = getCustomerItemNumber(price)

  return (
    <Flex flexWrap="wrap">
      <DT>{t('components.header.yourItemNumber')}</DT>
      <DD ml="1">{itemAlias}</DD>
    </Flex>
  )
}

ProductItemNumber.displayName = TAG

export default ProductItemNumber
