import { Button, ButtonProps, Icon } from '@chakra-ui/react'
import React, { FunctionComponent, ReactNode } from 'react'

import AddToCartIcon from 'assets/add_to_cart.svg?component'
import useSubmitAddToCart from 'components/modules/Product/hooks/useSubmitAddToCart'

interface ProductAddToCartProps extends ButtonProps {
  /**
   * The ID of the product to add to the cart.
   */
  productId: string
  /**
   * The variant ID of the product to add to the cart.
   */
  variantId: number
  /**
   * The quantity of the product to add to the cart.
   * @default 1
   */
  quantity?: number
  minQuantity?: number
  erpFacility: string
  erpWarehouse: string
  children?: ReactNode
}

/**
 * Used for E2E tests.
 */
const TAG = 'ProductAddToCart'

const ProductAddToCart: FunctionComponent<ProductAddToCartProps> = ({
  productId,
  variantId,
  quantity = 1,
  minQuantity = 1,
  erpFacility,
  erpWarehouse,
  children,
  ...props
}) => {
  const { onSubmit, isSubmitting } = useSubmitAddToCart({
    productId,
    variantId,
    quantity,
    erpFacility,
    erpWarehouse,
  })

  const isMinQuantityHigherThanQuantity = Boolean(minQuantity && minQuantity > quantity)

  return (
    <Button
      type="button"
      onClick={onSubmit}
      variant="secondary"
      leftIcon={<Icon boxSize="1rem" as={AddToCartIcon} />}
      isLoading={isSubmitting}
      isDisabled={isSubmitting || isMinQuantityHigherThanQuantity}
      w={{ base: 'full', sm: 'auto' }}
      {...props}
    >
      {children}
    </Button>
  )
}

ProductAddToCart.displayName = TAG

export default ProductAddToCart
