import useTranslation from 'next-translate/useTranslation'
import { useMemo } from 'react'

import { extendedAttributes } from 'config/product'

export interface TechnicalSpecNameSchema {
  name: string
  value?: string
}

function useTechnicalSpecName({ name, value }: TechnicalSpecNameSchema) {
  const { t } = useTranslation('product')
  const isExtendedTechnicalSpecification = extendedAttributes.includes(name)

  const technicalSpecName = useMemo(
    () =>
      name
        ? t(
            `components.technicalSpecifications.${name}${
              isExtendedTechnicalSpecification ? 'Title' : ''
            }` as TranslationKeys['product'],
            void 0,
            { default: name },
          )
        : '',
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [name, isExtendedTechnicalSpecification],
  )

  const technicalSpecValue = useMemo(
    () =>
      isExtendedTechnicalSpecification && value
        ? t(`components.technicalSpecifications.${name}.${value}` as TranslationKeys['product'], void 0, {
            default: value,
          })
        : value,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [name, value, isExtendedTechnicalSpecification],
  )

  return {
    technicalSpecName,
    technicalSpecValue,
  }
}

export default useTechnicalSpecName
