import type { Cart, ClientResponse } from '@commercetools/platform-sdk'

import getLineItemAction from 'commercetools/utils/getLineItemAction'
import { RoutePaths } from 'config/routes'
import apiAuthFetch from 'utils/apiAuthFetch'
import stringifyObjectValues from 'utils/stringifyObjectValues'

interface Variables {
  businessUnitKey: string
  cartId: string
  cartVersion: number
  quantity: number
  productId: string
  variantId: number
  custom?: {
    fields: {
      uniqueLineItemId: string
    }
  }
}

/**
 * Adds a line item to the cart.
 * @link https://docs.commercetools.com/api/projects/carts#add-lineitem
 */
function addLineItem({
  businessUnitKey,
  cartId,
  cartVersion,
  quantity,
  productId,
  variantId,
  custom, // Include the custom field here
}: Variables): Promise<ClientResponse<Cart>> {
  const stringifiedParams = stringifyObjectValues({ businessUnitKey })
  const params = new URLSearchParams(stringifiedParams).toString()
  const url = `${RoutePaths.cartsApi}${cartId}?${params}`

  return apiAuthFetch<ClientResponse<Cart>>(url, {
    method: 'POST',
    body: JSON.stringify({
      version: cartVersion,
      actions: [
        getLineItemAction({
          productId,
          variantId,
          quantity,
          custom, // Pass custom fields to the action
        }),
      ],
    }),
  })
}

export default addLineItem
