import type { CartAddLineItemAction } from '@commercetools/platform-sdk'

export interface CreateLineItemSchema {
  productId: string
  variantId: number
  quantity: number
  custom?: {
    fields: {
      uniqueLineItemId: string
    }
  }
}

function getLineItemAction({
  productId,
  variantId,
  quantity,
  custom, // Add custom to the function parameter
}: CreateLineItemSchema): CartAddLineItemAction {
  return {
    action: 'addLineItem',
    productId,
    variantId,
    quantity,
    ...(custom && {
      custom: {
        type: {
          typeId: 'type',
          key: 'lineItemAndOrderLineType',
        },
        fields: {
          uniqueLineItemId: custom.fields.uniqueLineItemId, // Pass the custom uniqueLineItemId
        },
      },
    }),
  }
}

export default getLineItemAction
