import { Flex, Spinner, Td, Tr } from '@chakra-ui/react'
import type { ProductProjection } from '@commercetools/platform-sdk'
import { useQuery } from '@tanstack/react-query'
import useTranslation from 'next-translate/useTranslation'
import React, { FunctionComponent, useMemo } from 'react'

import getLeadTimeAndDeliveryForm from 'azure/order/getLeadTimeAndDeliveryForm'
import { DT, DD } from 'components/elements/DescriptionListElements'

const TAG = 'DeliveryForm'

interface DeliveryFormProps {
  masterVariant: ProductProjection['masterVariant'] | undefined
  showAsRow?: boolean
}

const DeliveryForm: FunctionComponent<DeliveryFormProps> = ({ masterVariant, showAsRow }) => {
  const { t } = useTranslation()

  const { erpFacility, erpWarehouse } = masterVariant?.price?.custom?.fields || {}

  const erpTechspecDeliveryForm = useMemo(
    () => masterVariant?.attributes?.find((attr) => attr.name === 'erpTechspecDeliveryForm')?.value,
    [masterVariant],
  )

  const erpTechspecDiameterInInches = useMemo(
    () => masterVariant?.attributes?.find((attr) => attr.name === 'erpTechspecDiameterInInches')?.value,
    [masterVariant],
  )

  const isEnabled = !!erpTechspecDeliveryForm && !!erpFacility

  const { data, isLoading, isError } = useQuery(
    ['getLeadTimeAndDeliveryForm', erpFacility, erpTechspecDeliveryForm, erpWarehouse, erpTechspecDiameterInInches],
    getLeadTimeAndDeliveryForm,
    {
      enabled: isEnabled,
    },
  )

  if (isLoading && isEnabled) {
    return <Spinner boxSize="3" color="primary.blue" />
  }

  if (!masterVariant || !data || isError) {
    return null
  }

  return showAsRow ? (
    <Tr>
      <Td fontWeight="bold">{t('components.cart.deliveryForm')}</Td>
      <Td isNumeric>{data.deliveryFormName}</Td>
    </Tr>
  ) : (
    <Flex justifyContent="flex-start" flexWrap="wrap">
      <DT>{t('components.cart.deliveryForm')}</DT>
      <DD ml="1">{data.deliveryFormName}</DD>
    </Flex>
  )
}

DeliveryForm.displayName = TAG

export default DeliveryForm
