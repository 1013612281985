import { Flex } from '@chakra-ui/react'
import type { Attribute } from '@commercetools/platform-sdk'
import React, { FunctionComponent } from 'react'

import { DT, DD } from 'components/elements/DescriptionListElements'
import useTechnicalSpecName from 'components/modules/Product/hooks/useTechnicalSpecName'
import useLocalizedString from 'hooks/useLocalizedString'

/**
 * Used for E2E tests.
 */
const TAG = 'ProductListAttributesItem'

const ProductListAttributesItem: FunctionComponent<Attribute> = ({ name, value }) => {
  const display = useLocalizedString()

  const { technicalSpecName, technicalSpecValue } = useTechnicalSpecName({ name, value })

  return (
    <Flex justifyContent="flex-start" flexWrap="wrap">
      <DT>{`${technicalSpecName}:`}</DT>
      <DD ml="1">{typeof technicalSpecValue === 'object' ? display(technicalSpecValue) : technicalSpecValue}</DD>
    </Flex>
  )
}

ProductListAttributesItem.displayName = TAG

export default ProductListAttributesItem
