import { Flex } from '@chakra-ui/react'
import type { ProductProjection } from '@commercetools/platform-sdk'
import React, { FunctionComponent, useMemo } from 'react'

import DeliveryForm from 'components/elements/DeliveryForm'
import ProductListAttributesItem from 'components/modules/Products/ProductsList/ProductListAttributes/ProductListAttributesItem'
import getProductAttributesByItemGroup from 'components/modules/Products/utils/getProductAttributesByItemGroup'

interface ProductListAttributesProps {
  masterVariant: ProductProjection['masterVariant'] | undefined
}
/**
 * Used for E2E tests.
 */
const TAG = 'ProductListAttributes'

const ProductListAttributes: FunctionComponent<ProductListAttributesProps> = ({ masterVariant }) => {
  const filteredAttributes = useMemo(() => getProductAttributesByItemGroup(masterVariant?.attributes), [masterVariant])

  return (
    <Flex flexDirection="column" w={{ base: 'full', md: '280px' }}  mx="0">
      {filteredAttributes.map(({ name, value }) => (
        <ProductListAttributesItem key={`${name}-${value}`} name={name} value={value} />
      ))}
      <DeliveryForm masterVariant={masterVariant} />
    </Flex>
  )
}

ProductListAttributes.displayName = TAG

export default ProductListAttributes
